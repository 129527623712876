<template>
  <v-app class="app">
    <v-main>
      <AppBar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue'
export default {
  name: 'App',
  components: {
    AppBar,
    // ...
  },
  methods: {
    // ...
  },
  data: () => ({
    // ...
  }),
}
</script>

<style>
body {
  background-color: #2b2b37;
}
</style>

<style scoped>
.app {
  max-width: 1080px; 
  margin: auto;
}
</style>