import { createRouter, createWebHashHistory } from "vue-router"


const routes = [
  {
    path: '/',
    name: 'Index',
    // component: () => import('./pages/IndexPage.vue'),
    children: [
      {
        path: '',
        name: 'Index',
        component: () => import('./pages/IndexPage.vue'),
      },
      {
        path: 'start',
        name: 'Start',
        component: () => import('./pages/StartPage.vue'),
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  // base: "mzero",
  history: createWebHashHistory(),
  routes,
})

export default router
