import { createApp } from 'vue'
import router from './router.js'
import api from './api.js'
// import API_DOMAIN from './env.js'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

let app = createApp(App)
// app.config.globalProperties.API_DOMAIN = API_DOMAIN
app.use(router)
app.use(api)
app.use(vuetify).mount('#app')
