import axios from 'axios'

const API_BASE = '/mzero/api'

export default {
  install: (app, options) => {
    console.log(options)
    app.config.globalProperties.$api = {
      async get(url) {
        console.log('$api.get', url)
        return await fetch(API_BASE + url)
      },
      post(url, formData, headers, thenFunc, catchFunc) {
        console.log('$api.post', url)
        axios.post(API_BASE + url, formData, { headers }).then((res) => {
          thenFunc(res)
        }).catch((e) => {
          catchFunc(e)
        })
      }
    }
  }
}
