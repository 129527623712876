<template>
  <v-app-bar _color="#2b2b37">
    <template v-slot:prepend>
      <v-img width="30" src="../assets/logo-trans.png"></v-img>
    </template>
    <v-app-bar-title color="white">mZero 澳零科技</v-app-bar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
  setup() {
    // ...
  },
  components: {
    // ...
  },
  methods: {
    // ...
  },
  mounted() {
    // ...
  },
  data: () => ({
    // ...
  }),
}
</script>

<style scoped>
</style>